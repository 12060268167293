.container {
  grid-template-areas:
    "logo search nav"
    "currencies phones cart_wrapper";
}

.search {
  grid-area: search;  
}

.phones {
  grid-area: phones;
}
.cart_wrapper {
  grid-area: cart_wrapper;
}

.nav {
  grid-area: nav;
}

.cart_wrapper {
  grid-area: cart_wrapper;
}

.header_select{
  grid-area:header_select;
}

.currencies {
  grid-area: currencies;
}

.logo {
  grid-area: logo;
}

@media (max-width: 767px) {
  .container {
    grid-template-areas:
      "nav logo logo cart_wrapper"
      "search search search search"
      "currencies currencies currencies header_select"      
  }
}
