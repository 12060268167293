* {
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;
}

html,
body {
  /* font-family: "Robotto", sans-serif; */
 font-size:16px;
} 


@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  input:not([type='radio']),textarea {
    @apply outline outline-slate-700 outline-1
  }

  

 
}

@layer components {
.admin_dropdown_list {
    @apply absolute invisible opacity-0 top-full left-0 bg-gray-100/85 border border-gray-300 rounded-md
    min-w-[120px] z-50 py-1 shadow-md transition-opacity delay-100 ease-in-out
    
  }
  .droplist_wrapper{
    @apply absolute hidden w-full z-50 top-full left-0 text-lg py-2 
  }

  .droplist{
    @apply bg-gray-50 border border-gray-700 rounded-md  max-h-[500px] overflow-auto 
  }
  .checkout_droplist{
    @apply bg-gray-50 border border-gray-700 rounded-md  max-h-[300px] overflow-auto 
  }

}