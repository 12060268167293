.active_wave {
  animation: wave 0.4s ease-in-out;
}
@keyframes wave {
  to {
    width: 300px;
    height: 300px;
    opacity: 0;
  }
}
